export const SGP_INPUT_NUMBER_QUANTIDADE_AULAS =
  'SGP_INPUT_NUMBER_QUANTIDADE_AULAS';
export const SGP_INPUT_TEXT_NOME_EVENTO = 'SGP_INPUT_TEXT_NOME_EVENTO';
export const SGP_INPUT_CODIGO_EOL = 'SGP_INPUT_CODIGO_EOL';
export const SGP_INPUT_NOTA = 'SGP_INPUT_NOTA';
export const SGP_INPUT_NOTA_FINAL = 'SGP_INPUT_NOTA_FINAL';
export const SGP_INPUT_CONCEITO_FINAL = 'SGP_INPUT_CONCEITO_FINAL';
export const SGP_INPUT_JUSTIFIQUE_MOTIVO_DEVOLUCAO =
  'SGP_INPUT_JUSTIFIQUE_MOTIVO_DEVOLUCAO';
export const SGP_INPUT_NOME = 'SGP_INPUT_NOME';
export const SGP_INPUT_RF = 'SGP_INPUT_RF';
export const SGP_INPUT_NOME_ATIVIDADE = 'SGP_INPUT_NOME_ATIVIDADE';
export const SGP_INPUT_NOME_ESTUDANTE = 'SGP_INPUT_NOME_ESTUDANTE';
export const SGP_INPUT_NOME_CRIANCA_ESTUDANTE =
  'SGP_INPUT_NOME_CRIANCA_ESTUDANTE';
export const SGP_INPUT_LOCAL_ATIVIDADE_CONTRATURNO_MODAL =
  'SGP_INPUT_LOCAL_ATIVIDADE_CONTRATURNO_MODAL';
export const SGP_INPUT_ATIVIDADE_CONTRATURNO_MODAL =
  'SGP_INPUT_ATIVIDADE_CONTRATURNO_MODAL';
export const SGP_INPUT_NOME_COMPLETO_CONTATO_RESPONSAVEIS_MODAL =
  'SGP_INPUT_NOME_COMPLETO_CONTATO_RESPONSAVEIS_MODAL';
export const SGP_INPUT_GRAU_PARENTESCO_AFETIVIDADE_CONTATO_RESPONSAVEIS_MODAL =
  'SGP_INPUT_GRAU_PARENTESCO_AFETIVIDADE_CONTATO_RESPONSAVEIS_MODAL';
export const SGP_INPUT_TELEFONE_CONTATO_RESPONSAVEIS_MODAL =
  'SGP_INPUT_TELEFONE_CONTATO_RESPONSAVEIS_MODAL';
export const SGP_INPUT_TIPO_LOGRADOURO_ENDERECO_RESIDENCIAL_MODAL =
  'SGP_INPUT_TIPO_LOGRADOURO_ENDERECO_RESIDENCIAL_MODAL';
export const SGP_INPUT_LOGRADOURO_ENDERECO_RESIDENCIAL_MODAL =
  'SGP_INPUT_LOGRADOURO_ENDERECO_RESIDENCIAL_MODAL';
export const SGP_INPUT_NUMERO_ENDERECO_RESIDENCIAL_MODAL =
  'SGP_INPUT_NUMERO_ENDERECO_RESIDENCIAL_MODAL';
export const SGP_INPUT_COMPLEMENTO_ENDERECO_RESIDENCIAL_MODAL =
  'SGP_INPUT_COMPLEMENTO_ENDERECO_RESIDENCIAL_MODAL';
export const SGP_INPUT_BAIRRO_ENDERECO_RESIDENCIAL_MODAL =
  'SGP_INPUT_BAIRRO_ENDERECO_RESIDENCIAL_MODAL';
export const SGP_INPUT_CPF = 'SGP_INPUT_CPF';
export const SGP_INPUT_TELEFONE = 'SGP_INPUT_TELEFONE';
export const SGP_INPUT_EMAIL = 'SGP_INPUT_EMAIL';
export const SGP_INPUT_CEP = 'SGP_INPUT_CEP';
export const SGP_INPUT_ENDERECO = 'SGP_INPUT_ENDERECO';
export const SGP_INPUT_NUMERO = 'SGP_INPUT_NUMERO';
export const SGP_INPUT_COMPLEMENTO = 'SGP_INPUT_COMPLEMENTO';
export const SGP_INPUT_BAIRRO = 'SGP_INPUT_BAIRRO';
export const SGP_INPUT_CIDADE = 'SGP_INPUT_CIDADE';
export const SGP_INPUT_TITULO = 'SGP_INPUT_TITULO';
export const SGP_INPUT_QUANTIDADE_PARTICIPANTES =
  'SGP_INPUT_QUANTIDADE_PARTICIPANTES';
export const SGP_INPUT_NUMERO_EDUCADORES_ENVOLVIDOS =
  'SGP_INPUT_NUMERO_EDUCADORES_ENVOLVIDOS';
export const SGP_INPUT_NUMERO_EDUCANDOS_ENVOLVIDOS =
  'SGP_INPUT_NUMERO_EDUCANDOS_ENVOLVIDOS';
export const SGP_INPUT_NUMERO_CUIDADORES_RESPONSAVEIS_ENVOLVIDOS =
  'SGP_INPUT_NUMERO_CUIDADORES_RESPONSAVEIS_ENVOLVIDOS';
