export const SGP_RADIO_TIPO_AULA = 'SGP_RADIO_TIPO_AULA';
export const SGP_RADIO_RECORRENCIA = 'SGP_RADIO_RECORRENCIA';
export const SGP_RADIO_CATEGORIA = 'SGP_RADIO_CATEGORIA';
export const SGP_RADIO_EXIBIR_PLANOS_ENCERRADOS =
  'SGP_RADIO_EXIBIR_PLANOS_ENCERRADOS';
export const SGP_RADIO_EXIBIR_ENCAMINHAMENTOS_AEE_ENCERRADOS =
  'SGP_RADIO_EXIBIR_ENCAMINHAMENTOS_AEE_ENCERRADOS';
export const SGP_RADIO_EXIBIR_ENCAMINHAMENTOS_NAAPA_ENCERRADOS =
  'SGP_RADIO_EXIBIR_ENCAMINHAMENTOS_NAAPA_ENCERRADOS';
export const SGP_RADIO_IMPRIMIR_COMPONENTES_SEM_NOTA =
  'SGP_RADIO_IMPRIMIR_COMPONENTES_SEM_NOTA';
export const SGP_RADIO_DESCRICAO_OCORRENCIA = 'SGP_RADIO_DESCRICAO_OCORRENCIA';
export const SGP_RADIO_ATIVO_INATIVO = 'SGP_RADIO_ATIVO_INATIVO';
