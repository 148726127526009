export const SGP_COLLAPSE_NOVO_REGISTRO_INDIVIDUAL =
  'SGP_COLLAPSE_NOVO_REGISTRO_INDIVIDUAL';
export const SGP_COLLAPSE_REGISTROS_ANTERIORES =
  'SGP_COLLAPSE_REGISTROS_ANTERIORES';
export const SGP_COLLAPSE_TOTAL_CRIANCAS_RELATORIO_ACOMPANHAMENTO_APRENDIZADO =
  'SGP_COLLAPSE_TOTAL_CRIANCAS_RELATORIO_ACOMPANHAMENTO_APRENDIZADO';
export const SGP_COLLAPSE_TOTAL_CRIANCAS_RELATORIO_ACOMPANHAMENTO_APRENDIZADO_DRE =
  'SGP_COLLAPSE_TOTAL_CRIANCAS_RELATORIO_ACOMPANHAMENTO_APRENDIZADO';
export const SGP_COLLAPSE_PLANO_AULA = 'SGP_COLLAPSE_PLANO_AULA';
export const SGP_COLLAPSE_OBJETIVOS_APRENDIZAGEM_MEUS_OBJETIVOS =
  'SGP_COLLAPSE_OBJETIVOS_APRENDIZAGEM_MEUS_OBJETIVOS';
export const SGP_COLLAPSE_MURAL_GOOGLE_SALA_AULA =
  'SGP_COLLAPSE_MURAL_GOOGLE_SALA_AULA';
export const SGP_COLLAPSE_RECUPERACAO_CONTINUA =
  'SGP_COLLAPSE_RECUPERACAO_CONTINUA';
export const SGP_COLLAPSE_LICAO_CASA = 'SGP_COLLAPSE_LICAO_CASA';
export const SGP_COLLAPSE_DESENVOLVIMENTO_AULA =
  'SGP_COLLAPSE_DESENVOLVIMENTO_AULA';
export const SGP_COLLAPSE_OBJETIVOS_ESPECIFICOS_AULA =
  'SGP_COLLAPSE_OBJETIVOS_ESPECIFICOS_AULA';
export const SGP_COLLAPSE_ENCAMINHAMENTO_AEE =
  'SGP_COLLAPSE_ENCAMINHAMENTO_AEE';
export const SGP_COLLAPSE_LOCALIZAR_ESTUDANTE_CRIANCA =
  'SGP_COLLAPSE_LOCALIZAR_ESTUDANTE_CRIANCA';
export const SGP_COLLAPSE_OBSERVACOES_PLANO_AEE =
  'SGP_COLLAPSE_OBSERVACOES_PLANO_AEE';
export const SGP_COLLAPSE_PARECER_PLANO_AEE = 'SGP_COLLAPSE_PARECER_PLANO_AEE';
export const SGP_COLLAPSE_HISTORICO_ALTERACOES =
  'SGP_COLLAPSE_HISTORICO_ALTERACOES';
export const SGP_COLLAPSE_OBSERVACOES = 'SGP_COLLAPSE_OBSERVACOES';
