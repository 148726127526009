import styled from 'styled-components';

export const CaixaBimestre = styled.div`
  width: 100%;
  /* height: 38px; */
  border-radius: 3px;
  background-color: #dadada;
  text-align: center;
  padding: 10px;
`;

export const BoxTextoBimestre = styled.div`
  color: #42474a;
`;
