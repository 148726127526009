export function setQuestoesItineranciaAluno(payload) {
  return {
    type: '@itinerancia/setQuestoesItineranciaAluno',
    payload,
  };
}

export function setObjetivosItinerancia(payload) {
  return {
    type: '@itinerancia/setObjetivosItinerancia',
    payload,
  };
}
