export enum TIPO_SONDAGEM {
  LP_Escrita = 1,
  LP_Leitura = 2,
  LP_LeituraVozAlta = 3,
  LP_CapacidadeLeitura = 4,
  LP_ProducaoTexto = 5,
  MAT_Numeros = 6,
  MAT_CampoAditivo = 7,
  MAT_CampoMultiplicativo = 8,
  MAT_IAD = 9,
}
