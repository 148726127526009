const Colors = {
  Azul: 'Azul',
  AzulAnakiwa: 'AzulAnakiwa',
  Branco: 'Branco',
  Roxo: 'Roxo',
  CinzaBotao: 'CinzaBotao',
  Laranja: 'Laranja',
  Verde: 'Verde',
  Vermelho: 'Vermelho',
  CinzaMako: 'CinzaMako',
};

const Base = {
  Azul: '#086397',
  AzulAnakiwa: '#A4DAFB',
  AzulBordaCard: '#0A3DA3',
  AzulBordaCollapse: '#4072D6',
  AzulBreadcrumb: '#0C6598',
  AzulCalendario: '#10A3FB',
  AzulEventoCalendario: '#0097A7',
  Branco: '#FFF',
  CinzaBadge: '#F3F3F3',
  CinzaBarras: '#C8C8C8',
  CinzaBordaCalendario: '#C4C4C4',
  CinzaBotao: '#A4A4A4',
  CinzaCalendario: '#F7F9FA',
  CinzaDesabilitado: '#DADADA',
  CinzaDivisor: '#BFBFBF',
  CinzaFundo: '#F5F6F8',
  CinzaFundoEditor: '#F1F1F1',
  CinzaIconeNotificacao: '#707683',
  CinzaMako: '#42474A',
  CinzaMenu: '#EEEEEE',
  CinzaMenuItem: '#686868',
  CinzaTabela: '#FAFAFA',
  Laranja: '#F39200',
  LaranjaStatus: '#C0630E',
  LaranjaCalendario: '#C0640E',
  Preto: '#000',
  RosaCalendario: '#FEE4E2',
  Roxo: '#6933FF',
  RoxoDark: '#490CF5',
  RoxoBorda: '#E2DEF0',
  RoxoFundo: '#6331EA',
  RoxoClaro: '#B99FFF',
  RoxoEventoCalendario: '#9670FF',
  RoxoNotificacao: '#E7E6F8',
  Verde: '#297805',
  VermelhoAlerta: '#B40C02',
  VermelhoNotificacao: '#B40C02',
  Vermelho: '#B40C02',
  LaranjaAlerta: '#D06D12',
  VerdeBorda: '#20AA73',
  Bordo: '#864241',
  LaranjaFrequencia: '#C0630E',
};

const Active = {
  Azul: Base.Azul,
  AzulAnakiwa: Base.AzulAnakiwa,
  Roxo: Base.Roxo,
  CinzaBotao: Base.CinzaBotao,
  Laranja: Base.Laranja,
  Verde: Base.Verde,
  Vermelho: Base.Vermelho,
  Branco: Base.Branco,
};

const Hover = {
  Azul: '#064F79',
  Roxo: '#490CF5',
  Vermelho: '#AD0007',
};

const CoresGraficos = [
  '#D32F2F',
  '#7B1FA2',
  '#303F9F',
  '#0288D1',
  '#00796B',
  '#689F38',
  '#F57C00',
  '#5D4037',
  '#616161',
  '#C2185B',
  '#512DA8',
  '#1976D2',
  '#0097A7',
  '#388E3C',
  '#E64A19',
  '#E57373',
  '#BA68C8',
  '#7986CB',
  '#4FC3F7',
  '#4DD0E1',
  '#AED581',
  '#FFB74D',
  '#A1887F',
  '#90A4AE',
  '#F06292',
  '#9575CD',
  '#64B5F6',
  '#4DD0E1',
  '#81C784',
  '#FF8A65',
];

const ColorsCards = [
  '#AE2577',
  '#D06D12',
  '#086397',
  '#0C6B45',
  ...CoresGraficos,
];

export { Colors, Base, Active, Hover, CoresGraficos, ColorsCards };
