export const SGP_ALERT_JUSTIFICATIVA_PORCENTAGEM_NOTAS_CONCEITO =
  'SGP_ALERT_JUSTIFICATIVA_PORCENTAGEM_NOTAS_CONCEITO';
export const SGP_ALERT_ALERTA_PRINCIPAL_ESCOLHER_TURMA =
  'SGP_ALERT_ALERTA_PRINCIPAL_ESCOLHER_TURMA';
export const SGP_ALERT_NAO_PERMITE_LANCAMENTO_NOTA =
  'SGP_ALERT_NAO_PERMITE_LANCAMENTO_NOTA';
export const SGP_ALERT_PERIODO_FECHAMENTO_NAO_ESTA_ABERTO =
  'SGP_ALERT_PERIODO_FECHAMENTO_NAO_ESTA_ABERTO';
export const SGP_ALERT_ENCAMINHAMENTO_AEE_EM_OUTRA_UE =
  'SGP_ALERT_ENCAMINHAMENTO_AEE_EM_OUTRA_UE';
export const SGP_ALERT_PLANO_AEE_EM_OUTRA_UE =
  'SGP_ALERT_ENCAMINHAMENTO_AEE_EM_OUTRA_UE';
