export const SGP_JODIT_EDITOR_DESCRICAO_JUSTIFICATIVA =
  'SGP_JODIT_EDITOR_DESCRICAO_JUSTIFICATIVA';
export const SGP_JODIT_EDITOR_CADASTRO_AVALIACAO_DESCRICAO =
  'SGP_JODIT_EDITOR_CADASTRO_AVALIACAO_DESCRICAO';
export const SGP_JODIT_EDITOR_NOVO_REGISTRO_INDIVIDUAL =
  'SGP_JODIT_EDITOR_NOVO_REGISTRO_INDIVIDUAL';
export const SGP_JODIT_EDITOR_COMPENSACAO_AUSENCIA_DETALHAMENTO_ATIVIDADE =
  'SGP_JODIT_EDITOR_COMPENSACAO_AUSENCIA_DETALHAMENTO_ATIVIDADE';
export const SGP_JODIT_EDITOR_RECUPERACAO_CONTINUA =
  'SGP_JODIT_EDITOR_RECUPERACAO_CONTINUA';
export const SGP_JODIT_EDITOR_LICAO_CASA = 'SGP_JODIT_EDITOR_LICAO_CASA';
export const SGP_JODIT_EDITOR_DESENVOLVIMENTO_AULA =
  'SGP_JODIT_EDITOR_DESENVOLVIMENTO_AULA';
export const SGP_JODIT_EDITOR_OBJETIVOS_ESPECIFICOS_AULA =
  'SGP_JODIT_EDITOR_OBJETIVOS_ESPECIFICOS_AULA';
export const SGP_JODIT_EDITOR_DESCRICAO_ACAO =
  'SGP_JODIT_EDITOR_DESCRICAO_ACAO';
