export const SGP_DATA_AULA = 'SGP_DATA_AULA';
export const SGP_DATE_SELECIONAR_DATA_NOVO_REGISTRO_INDIVIDUAL =
  'SGP_DATE_SELECIONAR_DATA_NOVO_REGISTRO_INDIVIDUAL';
export const SGP_DATE_SELECIONAR_DATA_INICIO =
  'SGP_DATE_SELECIONAR_DATA_INICIO';
export const SGP_DATE_SELECIONAR_DATA_FIM = 'SGP_DATE_SELECIONAR_DATA_FIM';
export const SGP_DATE_SELECIONAR_DATA_INICIO_REGISTROS_ANTERIORES =
  'SGP_DATE_SELECIONAR_DATA_INICIO_REGISTROS_ANTERIORES';
export const SGP_DATE_SELECIONAR_DATA_FIM_REGISTROS_ANTERIORES =
  'SGP_DATE_SELECIONAR_DATA_FIM_REGISTROS_ANTERIORES';
export const SGP_DATE_SELECIONAR_DATA_FREQUENCIA_PLANO_AULA =
  'SGP_DATE_SELECIONAR_DATA_FREQUENCIA_PLANO_AULA';
export const SGP_DATE_ABERTURA_QUEIXA_INICIO =
  'SGP_DATE_ABERTURA_QUEIXA_INICIO';
export const SGP_DATE_ABERTURA_QUEIXA_FIM = 'SGP_DATE_ABERTURA_QUEIXA_FIM';
export const SGP_DATE_INICIO_OCORRENCIA = 'SGP_DATE_INICIO_OCORRENCIA';
export const SGP_DATE_FIM_OCORRENCIA = 'SGP_DATE_FIM_OCORRENCIA';
export const SGP_DATE_INICIO = 'SGP_DATE_INICIO';
export const SGP_DATE_FIM = 'SGP_DATE_FIM';
