export const SGP_TABLE_REGISTRO_INDIVIDUAL = 'SGP_TABLE_REGISTRO_INDIVIDUAL';
export const SGP_TABLE_REGISTRO_INDIVIDUAL_LINHA =
  'SGP_TABLE_REGISTRO_INDIVIDUAL_LINHA_';
export const SGP_TABLE_LISTA_ALUNOS = 'SGP_TABLE_LISTA_ALUNOS';
export const SGP_TABLE_LISTA_ALUNOS_AUSENCIA_COMPENSADA =
  'SGP_TABLE_LISTA_ALUNOS_AUSENCIA_COMPENSADA';
export const SGP_TABLE_OBJETIVOS_APRENDIZAGEM =
  'SGP_TABLE_OBJETIVOS_APRENDIZAGEM';
export const SGP_TABLE_OBJETIVOS_APRENDIZAGEM_ADICIONADOS =
  'SGP_TABLE_OBJETIVOS_APRENDIZAGEM_ADICIONADOS';
export const SGP_TABLE_LISTA_ALUNOS_ENCAMINHAMENTO_AEE =
  'SGP_TABLE_LISTA_ALUNOS_ENCAMINHAMENTO_AEE';
export const SGP_TABLE_LANCAMENTO_NOTAS = 'SGP_TABLE_LANCAMENTO_NOTAS';
export const SGP_TABLE_LANCAMENTO_NOTAS_LINHA =
  'SGP_TABLE_LANCAMENTO_NOTAS_LINHA';
export const SGP_TABLE_ENCAMINHAMENTO_NAAPA = 'SGP_TABLE_ENCAMINHAMENTO_NAAPA';
export const SGP_TABLE_ATIVIDADE_CONTRATURNO =
  'SGP_TABLE_ATIVIDADE_CONTRATURNO';
export const SGP_TABLE_ENDERECO_RESIDENCIAL = 'SGP_TABLE_ENDERECO_RESIDENCIAL';
export const SGP_TABLE_CONTATO_RESPONSAVEIS = 'SGP_TABLE_CONTATO_RESPONSAVEIS';
export const SGP_TABLE_DOCUMENTOS_PLANOS_TRABALHO =
  'SGP_TABLE_DOCUMENTOS_PLANOS_TRABALHO';
export const SGP_TABLE_HISTORICO_ATENDIMENTO =
  'SGP_TABLE_HISTORICO_ATENDIMENTO';
export const SGP_TABLE_TURMAS_PROGRAMA = 'SGP_TABLE_TURMAS_PROGRAMA';
export const SGP_TABLE_INFORMACOES_SRM = 'SGP_TABLE_INFORMACOES_SRM';
export const SGP_TABLE_SELECIONAR_ESTUDANTES =
  'SGP_TABLE_SELECIONAR_ESTUDANTES';
export const SGP_TABLE_FREQUENCIA_TURMA_PAP = 'SGP_TABLE_FREQUENCIA_TURMA_PAP';
export const SGP_TABLE_RELATORIO_DINAMICO_NAAPA =
  'SGP_TABLE_RELATORIO_DINAMICO_NAAPA';
export const SGP_TABLE_CADASTRO_ABAE = 'SGP_TABLE_CADASTRO_ABAE';
export const SGP_TABLE_INFORMES = 'SGP_TABLE_INFORMES';
export const SGP_TABLE_BUSCA_ATIVA_HISTORICO_REGISTRO_ACOES =
  'SGP_TABLE_BUSCA_ATIVA_HISTORICO_REGISTRO_ACOES';
export const SGP_TABLE_REGISTRO_ACOES = 'SGP_TABLE_REGISTRO_ACOES';
export const SGP_TABLE_REGISTRO_COLETIVO = 'SGP_TABLE_REGISTRO_COLETIVO';
export const SGP_TABLE_ESTUDANTES_DASHBOARD_NAAPA =
  'SGP_TABLE_ESTUDANTES_DASHBOARD_NAAPA';
export const SGP_TABLE_AVALIACOES_EXTERNAS_PROVA_SP =
  'SGP_TABLE_AVALIACOES_EXTERNAS_PROVA_SP';
export const SGP_TABLE_ESTUDANTES_PAP = 'SGP_TABLE_ESTUDANTES_PAP';
