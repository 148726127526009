export const SGP_SELECT_COMPONENTE_CURRICULAR =
  'SGP_SELECT_COMPONENTE_CURRICULAR';
export const SGP_SELECT_ANO_LETIVO = 'SGP_SELECT_ANO_LETIVO';
export const SGP_SELECT_DRE = 'SGP_SELECT_DRE';
export const SGP_SELECT_UE = 'SGP_SELECT_UE';
export const SGP_SELECT_MODALIDADE = 'SGP_SELECT_MODALIDADE';
export const SGP_SELECT_SEMESTRE = 'SGP_SELECT_SEMESTRE';
export const SGP_SELECT_TURMA = 'SGP_SELECT_TURMA';
export const SGP_SELECT_BIMESTRE = 'SGP_SELECT_BIMESTRE';
export const SGP_SELECT_PERIODO_POR_COMPONENTE_CURRICULAR =
  'SGP_SELECT_PERIODO_POR_COMPONENTE_CURRICULAR';
export const SGP_SELECT_TIPO_PENDENCIA = 'SGP_SELECT_TIPO_PENDENCIA';
export const SGP_SELECT_NOME_TIPO_PENDENCIA = 'SGP_SELECT_NOME_TIPO_PENDENCIA';
export const SGP_SELECT_TIPO_AVALIACAO = 'SGP_SELECT_TIPO_AVALIACAO';
export const SGP_SELECT_NOME_AVALIACAO = 'SGP_SELECT_NOME_AVALIACAO';
export const SGP_SELECT_OPCAO_ESTUDANTE = 'SGP_SELECT_OPCAO_ESTUDANTE';
export const SGP_SELECT_MODELO_BOLETIM = 'SGP_SELECT_MODELO_BOLETIM';
export const SGP_SELECT_VISUALIZACAO = 'SGP_SELECT_VISUALIZACAO';
export const SGP_SELECT_FORMATO = 'SGP_SELECT_FORMATO';
export const SGP_SELECT_TIPO_CALENDARIO = 'SGP_SELECT_TIPO_CALENDARIO';
export const SGP_SELECT_TIPO_EVENTO = 'SGP_SELECT_TIPO_EVENTO';
export const SGP_SELECT_FALTAS_COMPENSACAO = 'SGP_SELECT_FALTAS_COMPENSACAO';
export const SGP_SELECT_ESTUDANTE_CRIANCA = 'SGP_SELECT_ESTUDANTE_CRIANCA';
export const SGP_SELECT_SITUACAO = 'SGP_SELECT_SITUACAO';
export const SGP_SELECT_RESPONSAVEL = 'SGP_SELECT_RESPONSAVEL';
export const SGP_SELECT_NOTA = 'SGP_SELECT_NOTA';
export const SGP_SELECT_PAAIS_DRE = 'SGP_SELECT_PAAIS_DRE';
export const SGP_SELECT_CONCEITO_FINAL = 'SGP_SELECT_CONCEITO_FINAL';
export const SGP_SELECT_PRIORIDADE = 'SGP_SELECT_PRIORIDADE';
export const SGP_SELECT_SITUACAO_ENCAMINHAMENTO =
  'SGP_SELECT_SITUACAO_ENCAMINHAMENTO';
export const SGP_SELECT_SITUACAO_PLANO = 'SGP_SELECT_SITUACAO_PLANO';
export const SGP_SELECT_PAAI_RESPONSAVEL = 'SGP_SELECT_PAAI_RESPONSAVEL';
export const SGP_SELECT_TIPO_DOCUMENTO = 'SGP_SELECT_TIPO_DOCUMENTO';
export const SGP_SELECT_CLASSIFICACAO_DOCUMENTO =
  'SGP_SELECT_CLASSIFICACAO_DOCUMENTO';
export const SGP_SELECT_SITUACAO_ENCAMINHAMENTO_AEE =
  'SGP_SELECT_SITUACAO_ENCAMINHAMENTO_AEE';
export const SGP_SELECT_IMPRIMIR_DADOS_RESPONSAVEIS =
  'SGP_SELECT_IMPRIMIR_DADOS_RESPONSAVEIS';
export const SGP_SELECT_PREENCHER_DATA_IMPRESSAO =
  'SGP_SELECT_PREENCHER_DATA_IMPRESSAO';
export const SGP_SELECT_SITUACAO_ENCAMINHAMENTO_NAAPA =
  'SGP_SELECT_SITUACAO_ENCAMINHAMENTO_NAAPA';
export const SGP_SELECT_PORTA_ENTRADA_NAAPA = 'SGP_SELECT_PORTA_ENTRADA_NAAPA';
export const SGP_SELECT_FLUXO_ALERTA_NAAPA = 'SGP_SELECT_FLUXO_ALERTA_NAAPA';
export const SGP_SELECT_TIPO_SONDAGEM = 'SGP_SELECT_TIPO_SONDAGEM';
export const SGP_SELECT_PERIODO_SONDAGEM = 'SGP_SELECT_PERIODO_SONDAGEM';
export const SGP_SELECT_SITUACAO_REGISTRO_ITINERANCIA =
  'SGP_SELECT_SITUACAO_REGISTRO_ITINERANCIA';
export const SGP_SELECT_MESES = 'SGP_SELECT_MESES';
export const SGP_SELECT_PERIODO_PAP = 'SGP_SELECT_PERIODO_PAP';
export const SGP_SELECT_TIPO_OCORRENCIA = 'SGP_SELECT_TIPO_OCORRENCIA';
export const SGP_SELECT_ANO_ESCOLAR = 'SGP_SELECT_ANO_ESCOLAR';
export const SGP_SELECT_UF = 'SGP_SELECT_UF';
export const SGP_SELECT_PERFIS = 'SGP_SELECT_PERFIS';
export const SGP_SELECT_AUSENCIAS = 'SGP_SELECT_AUSENCIAS';
export const SGP_SELECT_ENTROU_CONTATO_FAMILIA_POR =
  'SGP_SELECT_ENTROU_CONTATO_FAMILIA_POR';
export const SGP_SELECT_TIPO_REUNIAO = 'SGP_SELECT_TIPO_REUNIAO';
export const SGP_SELECT_IMPRIMIR_ANEXO_NAAPA =
  'SGP_SELECT_IMPRIMIR_ANEXO_NAAPA';
export const SGP_SELECT_PARECER_CONCLUSIVO = 'SGP_SELECT_PARECER_CONCLUSIVO';
export const SGP_SELECT_TURMA_PAP = 'SGP_SELECT_TURMA_PAP';
export const SGP_SELECT_ABAE = 'SGP_SELECT_ABAE';
export const SGP_SELECT_MOTIVO_AUSENCIA = 'SGP_SELECT_MOTIVO_AUSENCIA';
export const SGP_SELECT_PROFESSOR = 'SGP_SELECT_PROFESSOR';
export const SGP_SELECT_TIPO_REL_PRODUTIVIDADE =
  'SGP_SELECT_TIPO_REL_PRODUTIVIDADE';
