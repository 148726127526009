export const LISTAO_TAB_FREQUENCIA = '1';
export const LISTAO_TAB_PLANO_AULA = '2';
export const LISTAO_TAB_AVALIACOES = '3';
export const LISTAO_TAB_FECHAMENTO = '4';
export const LISTAO_TAB_DIARIO_BORDO = '5';

export const PARAMETROS = {
  1: 'pendenciaFrequencia',
  2: 'pendenciaPlanoAula',
  3: 'pendenciaAvaliacoes',
  4: 'pendenciaFechamento',
  5: 'pendenciaDiarioBordo',
};
