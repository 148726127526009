import styled from 'styled-components';

export const Container = styled.div`
  input {
    width: 100%;
    height: 38px;
  }

  .ant-input-number {
    width: 100%;
  }

  padding: 7px;
`;
