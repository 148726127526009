const tipoQuestao = {
  Frase: 1,
  Texto: 2,
  Radio: 3,
  Combo: 4,
  Checkbox: 5,
  Upload: 6,
  InformacoesEscolares: 7,
  AtendimentoClinico: 8,
  ComboMultiplaEscolha: 9,
  Periodo: 10,
  FrequenciaEstudanteAEE: 11,
  PeriodoEscolar: 12,
  Numerico: 13,
  Data: 14,
  Endereco: 15,
  ContatoResponsaveis: 16,
  AtividadesContraturno: 17,
  EditorTexto: 18,
  TurmasPrograma: 19,
  InformacoesSrm: 20,
  InformacoesFrequenciaTurmaPAP: 21,
  ComboMultiplaEscolhaMes: 22,
  ProfissionaisEnvolvidos: 23,
  ComboDinamico: 24,
  ComboMultiplaEscolhaDinamico: 25,
  AvaliacoesExternasProvaSP: 26,
};

export default tipoQuestao;
