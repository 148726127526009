export const SGP_BUTTON_FILTRO_PRINCIPAL_APLICAR =
  'sgp-button-filtro-principal-aplicar';
export const SGP_SELECT_FILTRO_PRINCIPAL_ANOLETIVO =
  'sgp-select-filtro-principal-anoletivo';
export const SGP_SELECT_FILTRO_PRINCIPAL_TURMA =
  'sgp-select-filtro-principal-turma';
export const SGP_SELECT_FILTRO_PRINCIPAL_MODALIDADE =
  'sgp-select-filtro-principal-modalidade';
export const SGP_SELECT_FILTRO_PRINCIPAL_PERIODO =
  'sgp-select-filtro-principal-periodo';
export const SGP_SELECT_FILTRO_PRINCIPAL_DRE =
  'sgp-select-filtro-principal-dre';
export const SGP_SELECT_FILTRO_PRINCIPAL_UE = 'sgp-select-filtro-principal-ue';
export const SGP_CAMPO_FILTRO_PRINCIPAL_PESQUISAR_TURMA =
  'sgp-campo-filtro-principal-pesquisar-turma';
export const SGP_BUTTON_FILTRO_PRINCIPAL_EXPANDIR_RETRAIR_FILTRO =
  'SGP_BUTTON_FILTRO_PRINCIPAL_EXPANDIR_RETRAIR_FILTRO';
