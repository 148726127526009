export const SGP_CHECKBOX_EXIBIR_HISTORICO = 'SGP_CHECKBOX_EXIBIR_HISTORICO';
export const SGP_CHECKBOX_IMPRIMIR_ESTUDANTE_INATIVO =
  'SGP_CHECKBOX_IMPRIMIR_ESTUDANTE_INATIVO';
export const SGP_CHECKBOX_OBJETIVOS_APRENDIZAGEM_MEUS_OBJETIVOS =
  'SGP_CHECKBOX_OBJETIVOS_APRENDIZAGEM_MEUS_OBJETIVOS';
export const SGP_CHECKBOX_OBJETIVOS_ESPECIFICOS_DESENVOLVIMENTO_AULA =
  'SGP_CHECKBOX_OBJETIVOS_ESPECIFICOS_DESENVOLVIMENTO_AULA';
export const SGP_CHECKBOX_RECUPERACAO_CONTINUA =
  'SGP_CHECKBOX_RECUPERACAO_CONTINUA';
export const SGP_CHECKBOX_LICAO_CASA = 'SGP_CHECKBOX_LICAO_CASA';
export const SGP_CHECKBOX_EXIBIR_TURMAS_SEM_LANCAMENTO =
  'SGP_CHECKBOX_EXIBIR_TURMAS_SEM_LANCAMENTO';
