export const SGP_BUTTON_SAIR = 'SGP_BUTTON_SAIR';
export const SGP_BUTTON_VOLTAR = 'SGP_BUTTON_VOLTAR';
export const SGP_BUTTON_SALVAR = 'SGP_BUTTON_SALVAR';
export const SGP_BUTTON_CONFIRMAR = 'SGP_BUTTON_CONFIRMAR';
export const SGP_BUTTON_DESCARTAR = 'SGP_BUTTON_DESCARTAR';
export const SGP_BUTTON_SALVAR_RASCUNHO = 'SGP_BUTTON_SALVAR_RASCUNHO';
export const SGP_BUTTON_SALVAR_ALTERAR = 'SGP_BUTTON_SALVAR_ALTERAR';
export const SGP_BUTTON_CANCELAR = 'SGP_BUTTON_CANCELAR';
export const SGP_BUTTON_EXCLUIR = 'SGP_BUTTON_EXCLUIR';
export const SGP_BUTTON_ADICIONAR_TURMA = 'SGP_BUTTON_ADICIONAR_TURMA';
export const SGP_BUTTON_EXCLUIR_TURMA = 'SGP_BUTTON_EXCLUIR_TURMA';
export const SGP_BUTTON_GERAR = 'SGP_BUTTON_GERAR';
export const SGP_BUTTON_NOVO = 'SGP_BUTTON_NOVO';
export const SGP_BUTTON_CADASTRAR = 'SGP_BUTTON_CADASTRAR';
export const SGP_BUTTON_ALTERAR = 'SGP_BUTTON_ALTERAR';
export const SGP_BUTTON_ALTERAR_CADASTRAR = 'SGP_BUTTON_ALTERAR_CADASTRAR';
export const SGP_BUTTON_IMPRIMIR = 'SGP_BUTTON_IMPRIMIR';
export const SGP_BUTTON_APROVAR = 'SGP_BUTTON_APROVAR';
export const SGP_BUTTON_DEVOLVER = 'SGP_BUTTON_DEVOLVER';
export const SGP_BUTTON_ATRIBUIR = 'SGP_BUTTON_ATRIBUIR';
export const SGP_BUTTON_ACEITAR = 'SGP_BUTTON_ACEITAR';
export const SGP_BUTTON_RECUSAR = 'SGP_BUTTON_RECUSAR';
export const SGP_BUTTON_LIDA = 'SGP_BUTTON_LIDA';
export const SGP_BUTTON_ENVIAR = 'SGP_BUTTON_ENVIAR';
export const SGP_BUTTON_INDEFERIR = 'SGP_BUTTON_INDEFERIR';
export const SGP_BUTTON_ENCAMINHAR = 'SGP_BUTTON_ENCAMINHAR';
export const SGP_BUTTON_CONCLUIR_PARECER = 'SGP_BUTTON_CONCLUIR_PARECER';
export const SGP_BUTTON_VOLTAR_MODAL = 'SGP_BUTTON_VOLTAR_MODAL';
export const SGP_BUTTON_DEVOLVER_MODAL = 'SGP_BUTTON_DEVOLVER_MODAL';
export const SGP_BUTTON_SALVAR_MODAL = 'SGP_BUTTON_SALVAR_MODAL';
export const SGP_BUTTON_SELECIONAR_MODAL = 'SGP_BUTTON_SELECIONAR_MODAL';
export const SGP_BUTTON_CONFIRMAR_MODAL = 'SGP_BUTTON_CONFIRMAR_MODAL';
export const SGP_BUTTON_CONTINUAR_MODAL = 'SGP_BUTTON_CONTINUAR_MODAL';
export const SGP_BUTTON_CANCELAR_MODAL = 'SGP_BUTTON_CANCELAR_MODAL';
export const SGP_BUTTON_OK_MODAL = 'SGP_BUTTON_OK_MODAL';
export const SGP_BUTTON_CONFIRMAR_ALERTA = 'SGP_BUTTON_CONFIRMAR_ALERTA';
export const SGP_BUTTON_FECHAR_ALERTA = 'SGP_BUTTON_FECHAR_ALERTA';
export const SGP_BUTTON_ADD_OBJETIVOS_APRENDIZAGEM_DESENVOLVIMENTO =
  'SGP_BUTTON_ADD_OBJETIVOS_APRENDIZAGEM_DESENVOLVIMENTO';
export const SGP_BUTTON_COPIAR_AVALIACAO = 'SGP_BUTTON_COPIAR_AVALIACAO';
export const SGP_BUTTON_ORDENAR = 'SGP_BUTTON_ORDENAR';
export const SGP_BUTTON_REGENCIA = 'SGP_BUTTON_REGENCIA';
export const SGP_BUTTON_IMPRIMIR_RELATORIO_REGISTRO_INDIVIDUAL =
  'SGP_BUTTON_IMPRIMIR_RELATORIO_REGISTRO_INDIVIDUAL';
export const SGP_BUTTON_EXPANDIR_RETRAIR_TABELA_RETRATIL =
  'SGP_BUTTON_EXPANDIR_RETRAIR_TABELA_RETRATIL';
export const SGP_BUTTON_PROXIMO_TABELA_RETRATIL =
  'SGP_BUTTON_PROXIMO_TABELA_RETRATIL';
export const SGP_BUTTON_ANTERIOR_TABELA_RETRATIL =
  'SGP_BUTTON_ANTERIOR_TABELA_RETRATIL';
export const SGP_BUTTON_IMPRIMIR_DADOS_INDIVIDUAIS =
  'SGP_BUTTON_IMPRIMIR_DADOS_INDIVIDUAIS';
export const SGP_BUTTON_COPIAR_COMPENSACAO = 'SGP_BUTTON_COPIAR_COMPENSACAO';
export const SGP_BUTTON_COPIAR_CONTEUDO_PLANO_AULA =
  'SGP_BUTTON_COPIAR_CONTEUDO_PLANO_AULA';
export const SGP_BUTTON_ADICIONAR_ESTUDANTE_TABELA_AUSENCIA_COMPENSADA =
  'SGP_BUTTON_ADICIONAR_ESTUDANTE_TABELA_AUSENCIA_COMPENSADA';
export const SGP_BUTTON_REMOVER_ESTUDANTE_TABELA_AUSENCIA_COMPENSADA =
  'SGP_BUTTON_REMOVER_ESTUDANTE_TABELA_AUSENCIA_COMPENSADA';
export const SGP_BUTTON_IMPRIMIR_RELATORIO_PLANO_AULA =
  'SGP_BUTTON_IMPRIMIR_RELATORIO_PLANO_AULA';
export const SGP_BUTTON_ADICIONAR_TRANSFERENCIA_LISTA =
  'SGP_BUTTON_ADICIONAR_TRANSFERENCIA_LISTA';
export const SGP_BUTTON_REMOVER_TRANSFERENCIA_LISTA =
  'SGP_BUTTON_REMOVER_TRANSFERENCIA_LISTA';
export const SGP_BUTTON_PROXIMA_AULA = 'SGP_BUTTON_PROXIMA_AULA';
export const SGP_BUTTON_PROXIMO_PASSO = 'SGP_BUTTON_PROXIMO_PASSO';
export const SGP_BUTTON_ALTERAR_IMAGEM = 'SGP_BUTTON_ALTERAR_IMAGEM';
export const SGP_BUTTON_CANCELAR_ATRIBUICAO_RESPONSAVEL =
  'SGP_BUTTON_CANCELAR_ATRIBUICAO_RESPONSAVEL';
export const SGP_BUTTON_ATRIBUICAO_RESPONSAVEL =
  'SGP_BUTTON_ATRIBUICAO_RESPONSAVEL';
export const SGP_BUTTON_REMOVER_RESPONSAVEL = 'SGP_BUTTON_REMOVER_RESPONSAVEL';
export const SGP_BUTTON_CANCELAR_OBSERVACAO = 'SGP_BUTTON_CANCELAR_OBSERVACAO';
export const SGP_BUTTON_SALVAR_OBSERVACAO = 'SGP_BUTTON_SALVAR_OBSERVACAO';
export const SGP_BUTTON_EDITAR_OBSERVACAO = 'SGP_BUTTON_EDITAR_OBSERVACAO';
export const SGP_BUTTON_EXCLUIR_OBSERVACAO = 'SGP_BUTTON_EXCLUIR_OBSERVACAO';
export const SGP_BUTTON_EXCLUIR_USUARIO_MODAL =
  'SGP_BUTTON_EXCLUIR_USUARIO_MODAL';
export const SGP_BUTTON_ABRIR_MODAL_NOTIFICAR_USUARIOS =
  'SGP_BUTTON_ABRIR_MODAL_NOTIFICAR_USUARIOS';
export const SGP_BUTTON_EXCLUIR_ATIVIDADE_CONTRATURNO =
  'SGP_BUTTON_EXCLUIR_ATIVIDADE_CONTRATURNO';
export const SGP_BUTTON_EXCLUIR_CONTATO_RESPONSAVEIS =
  'SGP_BUTTON_EXCLUIR_CONTATO_RESPONSAVEIS';
export const SGP_BUTTON_ABRIR_ATIVIDADE_CONTRATURNO_MODAL =
  'SGP_BUTTON_ABRIR_ATIVIDADE_CONTRATURNO_MODAL';
export const SGP_BUTTON_VOLTAR_ATIVIDADE_CONTRATURNO_MODAL =
  'SGP_BUTTON_VOLTAR_ATIVIDADE_CONTRATURNO_MODAL';
export const SGP_BUTTON_ADICIONAR_ATIVIDADE_CONTRATURNO_MODAL =
  'SGP_BUTTON_ADICIONAR_ATIVIDADE_CONTRATURNO_MODAL';
export const SGP_BUTTON_ABRIR_CONTATO_RESPONSAVEIS_MODAL =
  'SGP_BUTTON_ABRIR_CONTATO_RESPONSAVEIS_MODAL';
export const SGP_BUTTON_VOLTAR_CONTATO_RESPONSAVEIS_MODAL =
  'SGP_BUTTON_VOLTAR_CONTATO_RESPONSAVEIS_MODAL';
export const SGP_BUTTON_ADICIONAR_CONTATO_RESPONSAVEIS_MODAL =
  'SGP_BUTTON_ADICIONAR_CONTATO_RESPONSAVEIS_MODAL';
export const SGP_BUTTON_VOLTAR_ENDERECO_RESIDENCIAL_MODAL =
  'SGP_BUTTON_VOLTAR_ENDERECO_RESIDENCIAL_MODAL';
export const SGP_BUTTON_ADICIONAR_ENDERECO_RESIDENCIAL_MODAL =
  'SGP_BUTTON_ADICIONAR_ENDERECO_RESIDENCIAL_MODAL';
export const SGP_BUTTON_DOWNLOAD_ARQUIVO = 'SGP_BUTTON_DOWNLOAD_ARQUIVO';
export const SGP_BUTTON_NOVO_ATENDIMENTO = 'SGP_BUTTON_NOVO_ATENDIMENTO';
export const SGP_BUTTON_CANCELAR_ATENDIMENTO =
  'SGP_BUTTON_CANCELAR_ATENDIMENTO';
export const SGP_BUTTON_SALVAR_ATENDIMENTO = 'SGP_BUTTON_SALVAR_ATENDIMENTO';
export const SGP_BUTTON_EXCLUIR_ATENDIMENTO = 'SGP_BUTTON_EXCLUIR_ATENDIMENTO';
export const SGP_BUTTON_GERAR_RELATORIO = 'SGP_BUTTON_GERAR_RELATORIO';
export const SGP_BUTTON_ENCERRAR_ENCAMINHAMENTO_NAAPA =
  'SGP_BUTTON_ENCERRAR_ENCAMINHAMENTO_NAAPA';
export const SGP_BUTTON_CANCELAR_MODAL_ENCERRAMENTO =
  'SGP_BUTTON_CANCELAR_MODAL_ENCERRAMENTO';
export const SGP_BUTTON_SALVAR_MODAL_ENCERRAMENTO =
  'SGP_BUTTON_SALVAR_MODAL_ENCERRAMENTO';
export const SGP_BUTTON_CANCELAR_MODAL_OBSERVACOES_COMPLEMENTARES =
  'SGP_BUTTON_CANCELAR_MODAL_OBSERVACOES_COMPLEMENTARES';
export const SGP_BUTTON_SALVAR_MODAL_OBSERVACOES_COMPLEMENTARES =
  'SGP_BUTTON_SALVAR_MODAL_OBSERVACOES_COMPLEMENTARES';
export const SGP_BUTTON_REABRIR_ENCAMINHAMENTO_NAAPA =
  'SGP_BUTTON_REABRIR_ENCAMINHAMENTO_NAAPA';
export const SGP_BUTTON_CONFERIR_CONSELHO_CLASSE =
  'SGP_BUTTON_CONFERIR_CONSELHO_CLASSE';
export const SGP_BUTTON_PRINCIPAL_MODAL_BIMESTRES =
  'SGP_BUTTON_PRINCIPAL_MODAL_BIMESTRES';
export const SGP_BUTTON_SECUNDARIO_MODAL_BIMESTRES =
  'SGP_BUTTON_SECUNDARIO_MODAL_BIMESTRES';
export const SGP_BUTTON_PRINCIPAL_MODAL_INCONSISTENCIAS =
  'SGP_BUTTON_PRINCIPAL_MODAL_INCONSISTENCIAS';
export const SGP_BUTTON_SECUNDARIO_MODAL_INCONSISTENCIAS =
  'SGP_BUTTON_SECUNDARIO_MODAL_INCONSISTENCIAS';
export const SGP_BUTTON_FILTRO_AVANCADO = 'SGP_BUTTON_FILTRO_AVANCADO';
export const SGP_BUTTON_FILTRAR = 'SGP_BUTTON_FILTRAR';
export const SGP_BUTTON_BUSCA_ATIVA_NOVO_REGISTRO_ACAO =
  'SGP_BUTTON_BUSCA_ATIVA_NOVO_REGISTRO_ACAO';
export const SGP_BUTTON_BUSCA_ATIVA_ATUALIZAR_DADOS_RESPONSAVEL =
  'SGP_BUTTON_BUSCA_ATIVA_ATUALIZAR_DADOS_RESPONSAVEL';
export const SGP_BUTTON_SALVAR_EDICAO_PARECER_CONCLUSIVO =
  'SGP_BUTTON_SALVAR_EDICAO_PARECER_CONCLUSIVO';
export const SGP_BUTTON_CANCELAR_EDICAO_PARECER_CONCLUSIVO =
  'SGP_BUTTON_CANCELAR_EDICAO_PARECER_CONCLUSIVO';
export const SGP_BUTTON_COPIAR_PAP = 'SGP_BUTTON_COPIAR_PAP';
