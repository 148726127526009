export enum ROUTES {
  PRINCIPAL = '/',
  MEUS_DADOS = '/meus-dados',
  NOTIFICACOES = '/notificacoes',
  PLANO_CICLO = '/planejamento/plano-ciclo',
  PLANO_ANUAL = '/planejamento/plano-anual',
  ATRIBUICAO_RESPONSAVEIS_LISTA = '/gestao/atribuicao-responsaveis/lista',
  ATRIBUICAO_RESPONSAVEIS = '/gestao/atribuicao-responsaveis',
  ATRIBUICAO_ESPORADICA_LISTA = '/gestao/atribuicao-esporadica',
  ATRIBUICAO_ESPORADICA_NOVO = '/gestao/atribuicao-esporadica/novo',
  ATRIBUICAO_CJ_LISTA = '/gestao/atribuicao-cjs',
  ATRIBUICAO_CJ_FORM = '/gestao/atribuicao-cjs/editar',
  REINICIAR_SENHA = '/usuarios/reiniciar-senha',
  SUPORTE = '/usuarios/suporte',
  TIPO_EVENTOS = '/calendario-escolar/tipo-eventos',
  PERIODOS_ESCOLARES = '/calendario-escolar/periodos-escolares',
  TIPO_CALENDARIO_ESCOLAR = '/calendario-escolar/tipo-calendario-escolar',
  TIPO_FERIADO = '/calendario-escolar/tipo-feriado',
  EVENTOS = '/calendario-escolar/eventos',
  CALENDARIO_ESCOLAR = '/calendario-escolar',
  CALENDARIO_PROFESSOR = '/calendario-escolar/calendario-professor',
  CALENDARIO_PROFESSOR_II = '/calendario-escolar/calendario-professor/v2',
  CADASTRO_DE_AULA = '/calendario-escolar/calendario-professor/cadastro-aula',
  CADASTRO_DE_AVALIACAO = '/calendario-escolar/calendario-professor/cadastro-avaliacao',
  FREQUENCIA_PLANO_AULA = '/diario-classe/frequencia-plano-aula',
  ACOMPANHAMENTO_FREQUENCIA = '/diario-classe/acompanhamento-frequencia',
  TIPO_AVALIACAO = '/configuracoes/tipo-avaliacao',
  NOTAS = '/diario-classe/notas',
  AULA_DADA_AULA_PREVISTA = '/diario-classe/aula-dada-aula-prevista',
  REGISTRO_POA = '/diario-classe/registro-poa',
  COMPENSACAO_AUSENCIA = '/diario-classe/compensacao-ausencia',
  FECHAMENTO_BIMESTRE = '/fechamento/fechamento-bimestre',
  PERIODO_FECHAMENTO_ABERTURA = '/calendario-escolar/periodo-fechamento-abertura',
  PAP = '/relatorios/pap/relatorio-graficos',
  PERIODO_FECHAMENTO_REABERTURA = '/calendario-escolar/periodo-fechamento-reabertura',
  RELATORIO_PAP_ACOMPANHAMENTO = '/relatorios/pap/relatorio-preenchimento',
  PENDENCIAS_FECHAMENTO = '/fechamento/pendencias-fechamento',
  ACOMPANHAMENTO_COMUNICADOS = '/gestao/comunicados',
  CONSELHO_CLASSE = '/fechamento/conselho-classe',
  SEM_PERMISSAO = '/sem-permissao',
  TERRITORIO_SABER = '/planejamento/plano-anual-territorio-saber',
  HISTORICO_ESCOLAR = '/relatorios/historico-escolar',
  RELATORIO_BOLETIM_SIMPLES = '/relatorios/diario-classe/boletim-simples',
  ATA_FINAL_RESULTADOS = '/relatorios/atas/ata-final-resultados',
  RELATORIO_FREQUENCIA = '/relatorios/frequencia/frequencia',
  DIARIO_BORDO = '/diario-classe/diario-bordo',
  RELATORIO_PENDENCIAS = '/relatorios/gestao/pendencias',
  CARTA_INTENCOES = '/planejamento/carta-intencoes',
  RELATORIO_PARECER_CONCLUSIVO = '/relatorios/parecer-conclusivo',
  RELATORIO_NOTAS_CONCEITOS_FINAIS = '/relatorios/notas-conceitos-finais',
  RELATORIO_COMPENSACAO_AUSENCIA = '/relatorios/compensacao-ausencia',
  DEVOLUTIVAS = '/diario-classe/devolutiva',
  REGISTRO_INDIVIDUAL = '/diario-classe/registro-individual',
  CONTROLE_GRADE = '/relatorios/diario-classe/controle-grade',
  RELATORIO_PLANEJAMENTO_DIARIO = '/relatorios/diario-classe/planejamento-diario',
  SONDAGEM = '/sondagem',
  HISTORICO_NOTIFICACOES = '/relatorios/notificacoes/historico-notificacoes',
  DOCUMENTOS_PLANOS_TRABALHO = '/gestao/documentos-planos-trabalho',
  RELATORIO_USUARIOS = '/relatorios/gestao/usuarios',
  RELATORIO_ATRIBUICAO_CJ = '/relatorios/gestao/atribuicao-cj',
  RELATORIO_ALTERACAO_NOTAS = '/relatorios/fechamento/historico-alteracao-notas',
  RELATORIO_LEITURA = '/relatorios/escola-aqui/leitura',
  RELATORIO_ESCOLA_AQUI_ADESAO = '/relatorios/escola-aqui/adesao',
  RELATORIO_AEE_ENCAMINHAMENTO = '/aee/encaminhamento',
  RELATORIO_AEE_ENCAMINHAMENTO_IMPRESSAO = '/relatorios/aee/encaminhamento',
  OCORRENCIAS = '/gestao/ocorrencias',
  RELATORIO_AEE_REGISTRO_ITINERANCIA = '/aee/registro-itinerancia',
  RELATORIO_AEE_PLANO = '/aee/plano',
  RELATORIO_AEE_PLANO_IMPRESSAO = '/relatorios/aee/plano',
  ACOMPANHAMENTO_APRENDIZAGEM = '/fechamento/acompanhamento-aprendizagem',
  RELATORIO_DEVOLUTIVAS = '/relatorios/planejamento/devolutivas',
  DASHBOARD_ESCOLA_AQUI = '/dashboard/escola-aqui',
  DASHBOARD_AEE = '/dashboard/aee',
  DASHBOARD_REGISTRO_ITINERANCIA = '/dashboard/registro-itinerancia',
  ACOMPANHAMENTO_FECHAMENTO = '/fechamento/acompanhamento-fechamento',
  DASHBOARD_FREQUENCIA = '/dashboard/frequencia',
  DASHBOARD_DEVOLUTIVAS = '/dashboard/devolutivas',
  DASHBOARD_INFORMACOES_ESCOLARES = '/dashboard/informacoes-escolares',
  DASHBOARD_REGISTRO_INDIVIDUAL = '/dashboard/registro-individual',
  DASHBOARD_DIARIO_BORDO = '/dashboard/diario-bordo',
  RELATORIO_ACOMPANHAMENTO_FECHAMENTO = '/relatorios/fechamentos/acompanhamento-fechamento',
  DASHBOARD_RELATORIO_ACOMPANHAMENTO_APRENDIZAGEM = '/dashboard/acompanhamento-aprendizagem',
  DASHBOARD_FECHAMENTO = '/dashboard/fechamento',
  ATA_BIMESTRAL = '/relatorios/atas/ata-bimestral',
  RELATORIO_ACOMPANHAMENTO_REGISTROS = '/relatorios/gestao/acompanhamento-registros',
  LISTAO = '/diario-classe/listao',
  LISTAO_OPERACOES = '/diario-classe/listao/operacoes',
  RELATORIO_FREQUENCIA_MENSAL = '/relatorios/frequencia/mensal',
  DASHBOARD_NAAPA = '/dashboard/naapa',
  ENCAMINHAMENTO_NAAPA = '/naapa/encaminhamento',
  RELATORIO_ENCAMINHAMENTO_NAAPA = '/relatorios/naapa/encaminhamento',
  LOGIN = '/login',
  REDEFINIR_SENHA = '/redefinir-senha',
  REDEFINIR_SENHA_TOKEN = '/redefinir-senha/:token',
  RECUPERAR_SENHA = '/recuperar-senha',
  RELATORIO_SONDAGEM_ANALITICO = '/relatorios/sondagem/analitico',
  RELATORIO_AEE_REGISTRO_ITINERANCIA_IMPRESSAO = '/relatorios/aee/registro-itinerancia',
  RELATORIO_CONTROLE_FREQUENCIA_MENSAL = '/relatorios/frequencia/controle-mensal',
  AUTENTICACAO_INTEGRACOES_FREQUENCIA = '/autenticacao/integracoes/frequencia/:tokenIntegracaoFrequencia',
  RELATORIO_PAP = '/relatorios/pap/relatorio-pap',
  RELATORIO_OCORRENCIAS = '/relatorios/gestao/ocorrencias',
  RELATORIO_DINAMICO_NAAPA = '/naapa/relatorio-dinamico',
  CADASTRO_ABAE = '/cadastro-abae',
  CADASTRO_ABAE_NOVO = '/cadastro-abae/novo',
  CADASTRO_ABAE_EDICAO = '/cadastro-abae/:id',
  INFORMES = '/informes',
  INFORMES_NOVO = '/informes/novo',
  INFORMES_EDICAO = '/informes/:id',
  BUSCA_ATIVA_CONSULTA_CRIANCAS_ESTUDANTES_AUSENTES = '/busca-ativa/criancas-estudantes/ausentes',
  BUSCA_ATIVA_HISTORICO_REGISTRO_ACOES = '/busca-ativa/criancas-estudantes/ausentes/historico-registro-acoes',
  BUSCA_ATIVA_HISTORICO_REGISTRO_ACOES_NOVO = '/busca-ativa/criancas-estudantes/ausentes/historico-registro-acoes/novo',
  BUSCA_ATIVA_HISTORICO_REGISTRO_ACOES_EDICAO = '/busca-ativa/criancas-estudantes/ausentes/historico-registro-acoes/:id',
  BUSCA_ATIVA_REGISTRO_ACOES = '/busca-ativa/registro-acoes',
  BUSCA_ATIVA_REGISTRO_ACOES_NOVO = '/busca-ativa/registro-acoes/novo',
  BUSCA_ATIVA_REGISTRO_ACOES_EDICAO = '/busca-ativa/registro-acoes/:id',
  DASHBOARD_NAAPA_BUSCA_ATIVA = '/dashboard/busca-ativa',
  NAAPA_REGISTRO_COLETIVO = '/naapa/registro-coletivo',
  NAAPA_REGISTRO_COLETIVO_NOVO = '/naapa/registro-coletivo/novo',
  NAAPA_REGISTRO_COLETIVO_EDICAO = '/naapa/registro-coletivo/:id',
  MAPEAMENTO_ESTUDANTES = '/diario-classe/mapeamento-estudantes',
  RELATORIO_MAPEAMENTO_ESTUDANTES = '/relatorios/diario-classe/mapeamento-estudantes',
  RELATORIO_BUSCA_ATIVA = '/relatorios/naapa/busca-ativa',
  RELATORIO_PRODUTIVIDADE = '/relatorios/frequencia/produtividade',
}
