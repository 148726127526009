import styled from 'styled-components';
import { Base } from '~/componentes/colors';

export const Container = styled.div`
  font-size: 14px;
  line-height: 22px;

  i,
  span {
    color: ${Base.Azul};
    font-weight: 700;
  }
`;
